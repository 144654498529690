import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import { css } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const PROJECTS = [
  {
    name: "Dermalife",
    type: "Desarrollo de bot automatizado",
    shortDescription:
      "Gracias a la automatización por medio de chatbot se aumenta la eficiencia con la que se le puede dar respuestas a los clientes de Dermalife.",
    description:
      "Más de 15.000 mensajes respondidos en menos de 90 días, disminución del tiempo de atención en un 73%, data de alto valor para la ejecución de campañas de mercadeo, las cuales en algunos casos se realizan directamente por este mismo medio, disminución del estrés laboral y gran aceptación por parte de los clientes.",
    challenge:
      "Debido a la naturaleza operativa y al alto flujo de mensajes que llegaban día a día al WhatsApp empresarial, se presentaba un servicio lento, en muchas ocasiones generando abandono, al ser un proceso manual se presentaban inconsistencia en la información, no se tenían métricas establecidas como: hora de mayor tráfico de mensajes, tipo de servicio más solicitado, entre otros.",
    ourWay:
      "Debido a la operatividad del proceso analizado y los largos tiempos de respuesta se decide implementar un Chatbot integrado con inteligencia artificial, el cual solicita todos los datos de la persona, almacena la información brindada y tiempos de respuesta, con toda la información almacenada, entrega el proceso de venta a un asesor físico, el cual se encarga de brindar asesoría personaliza al cliente y finaliza la venta.",
    slug: "dermalife",
  },
  {
    name: "Kipi",
    type: "Desarrollo de aplicativo",
    shortDescription:
      "Por medio de herramientas digitales se convierte un negocio de mantenimientos tradicional en una amplia red de facility management automatizada.",
    description:
      "Cambio de practicas operativas a una manera sencilla y digital de gestionar el mantenimiento de grandes establecimientos en diferentes sectores, ampliando su alcance no solo a empresas sino a personas naturales brindando una experiencia ágil, profesional y efectiva.",
    challenge:
      "Se necesitaba crear una mejor experiencia para los dueños de negocios en todos los temas de mantenimiento de planta física. A su vez digitalizar todos los procesos para tener información consistente y trazable .",
    ourWay:
      "Se decide tecnificar todo el proceso de solicitud de servicio, desde que el usuario pide atención. Se cambia desde la forma de hacer la solicitud para darle una respuesta mas precisa, notificar el proceso de su pedido; hasta la automatización en la plataforma administrativa. Ordenando las solicitudes. \nSe muestra la disponibilidad de los técnicos, asignándolos y manteniendo la operatividad a un mínimo.",
    slug: "kipi",
  },
  {
    name: "Domicilios Cubit",
    type: "Desarrollo del aplicativo",
    shortDescription:
      "Se le da la posibilidad a negocios de gestionar sus domicilios de manera ordenada, trazable y transparente, todo esto mientras mantienen control de sus finanzas y tramites.",
    description:
      "Manteniendo la autonomía de sus negocios, empresas han logrado por medio de Domicilios Cubit gestionar todos sus procesos de manera automatizada y ordenada, sosteniendo sus prácticas de entregas, mientras mantienen el control de sus domiciliarios, finanzas y tramites.",
    challenge:
      "Diferentes tipos de negocios manifiestan la necesidad de gestionar sus procesos de domicilios y entregas de manera trazable y ordenada.",
    ourWay:
      "Se realiza un plataforma de cara al negocio, donde pueden listar todos sus productos, permitiendo un fácil manejo por parte del operario, a su vez se puede determinar los costos de envío de manera automática detectando el recorrido realizado. El aplicativo administrativo permite llevar control y trazabilidad con cada pedido a su vez que ver la eficiencia de las rutas tomadas por el transportador.",
    slug: "domicilios-cubit",
  },
  {
    name: "Mi Contrato",
    type: "Desarrollo del aplicativo",
    shortDescription:
      "Gracias a un aplicativo web se automatiza la creación de documentos legales, permitiendo al usuario realizar procesos cotidianamente extensos y costosos de manera simple.",
    description:
      "Se busca democratizar el acceso a la creación de contratos de calidad a todas las personas, creando documentos desarrollados por abogados expertos en cada tema, de forma rápida, sencilla, a la medida y con precios justos.",
    challenge:
      "Construir un documento legal implica un alto uso de tiempo y dinero para el usuario, queremos reducir esto a un mínimo y permitir que cualquier persona pueda realizar sus documentos de manera rápida, económica y profesional.",
    ourWay:
      "Creamos un aplicativo web el cual permite a los usuarios recibir información sobre los tipos de contratos y orientarlos hacia el tipo de contrato necesitan, permitiéndoles crear contratos de manera automatizada y parametrizable. Todo esto contando con un equipo de abogados expertos en cada tema, generando documentos de la mas alta calidad.",
    slug: "mi-contrato",
  },
  {
    name: "Domicilios Gana",
    type: "Desarrollo del aplicativo",
    shortDescription:
      "Se desarrolla una aplicación móvil para los domiciliarios, una plataforma administrativa que permite tener trazabilidad de todo el proceso y un formulario web de cara al cliente que permite al usuario realizar pedidos directamente a Gana, facilitando la gestion interna.",
    description:
      "Gracias a domicilios Gana se logra realizar toda la gestión del proceso de manera sencilla y rápida, obteniendo data de alta calidad que permite conocer el cliente y tomar mejores decisiones.",
    challenge:
      "Construir una plataforma en tiempo record, que permita llevar los servicios de Gana directamente a los usuarios, dando orden y trazabilidad a todo el proceso.",
    ourWay:
      "Creamos una aplicación, plataforma administrativa y formulario web en tan solo 9 días, permitiendo a los usuarios solicitar servicios a domicilio de los productos de Gana. Realizamos mejoras constantes detectando los requerimientos del cliente, buscando entregar valor en etapas tempranas.",
    slug: "domicilios-gana",
  },
];

const buttonCss = css`
  cursor: pointer;
  display: block;
  transition: transform 0.4s ease;

  &:hover {
    transform: scale(1.5);
  }
`;

export interface ProjectsPageProps {}

const ProjectsPage: React.FC<ProjectsPageProps> = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const updateCurrentIndex = (amount: number) => {
    let newAmount = currentIndex + amount;

    if (newAmount >= PROJECTS.length) {
      newAmount = 0;
    }

    if (newAmount < 0) {
      newAmount = PROJECTS.length - 1;
    }

    setCurrentIndex(newAmount);
  };

  return (
    <Layout transparentHeaderOnTop={false}>
      <SEO title="Proyectos" />
      <div css={{ marginBottom: "-5em" }} className="container mt-6 pt-6">
        <div
          css={{
            position: "relative",
          }}
          className="mt-6"
        >
          {PROJECTS.map(
            (
              {
                name,
                type,
                shortDescription,
                slug,
                description,
                challenge,
                ourWay,
              },
              index
            ) => (
              <div
                css={{
                  top: 0,
                  width: "100%",
                  display: index !== currentIndex ? "none" : "block",
                }}
                key={`project-${index}`}
              >
                <div
                  css={{
                    backgroundColor: "#FFF",
                    borderRadius: "2em",
                    overflow: "hidden",
                    boxShadow: "0 15px 20px -10px rgba(0, 0, 0, 0.4)",
                  }}
                  className="columns mx-4 mb-0"
                >
                  <div
                    className="column is-two-thirds"
                    css={{
                      backgroundImage: `url(/images/projects/${slug}/header.png)`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      position: "relative",
                      "&:after": {
                        content: "''",
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0.5,
                        backgroundColor: "#0A286A",
                        zIndex: 0,
                      },
                    }}
                  >
                    <div
                      css={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        position: "relative",
                        zIndex: 2,
                      }}
                      className="mx-4 my-4 h-100"
                    >
                      <h2 className="text-accent display-5 mb-4">
                        <span className="text-thin">Nuestro</span>
                        <br />
                        Trabajo
                      </h2>
                      <p className="text-white mb-0">
                        Descubre como materializamos grandes ideas en productos
                        asombrosos.
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="my-6 px-4">
                      <div
                        css={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2 className="text-obscure mb-0">{name}</h2>

                        <div css={{ display: "flex", alignItems: "center" }}>
                          <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ marginRight: 10 }}
                            css={buttonCss}
                            onClick={() => updateCurrentIndex(-1)}
                          />
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            css={buttonCss}
                            onClick={() => updateCurrentIndex(1)}
                          />
                        </div>
                      </div>

                      <p className="text-secondary">
                        <b>{type}</b>
                      </p>
                      <p className="text-obscure">{shortDescription}</p>
                    </div>
                  </div>
                </div>
                <div
                  css={{
                    width: "75%",
                    maxWidth: "500px",
                    margin: "0 auto",
                    borderLeft: "5px solid #CA4268",
                    padding: "2em",
                  }}
                >
                  <p className="text-obscure">{description}</p>
                </div>
                <div
                  className="my-6 mx-4"
                  css={{
                    backgroundColor: "#FFF",
                    borderRadius: "2em",
                    overflow: "hidden",
                  }}
                >
                  <div
                    css={css`
                      flex-direction: column-reverse;
                      display: flex;

                      @media (min-width: 768px) {
                        flex-direction: row;
                      }
                    `}
                    className="columns"
                  >
                    <div className="column">
                      <div
                        css={{
                          width: "300px",
                          height: "600px",
                          borderRadius: "30px 30px 0 0",
                          border: "12px solid black",
                          borderBottom: "none",
                          margin: "3em auto -20px auto",
                          backgroundImage: `url(/images/projects/${slug}/phone.png)`,
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          position: "relative",
                          overflow: "none",
                        }}
                      >
                        <div
                          css={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background:
                              "linear-gradient(-35deg, white, transparent 75%)",
                            clipPath: "polygon(0 0, 0% 100%, 100% 0)",
                            zIndex: 2,
                            borderRadius: "20px 20px 0 0",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="py-6 px-6">
                        <div className="mb-6">
                          <h2 className="mb-2 text-obscure">El Reto</h2>
                          <p
                            className="mb-0 text-obscure"
                            style={{ opacity: 0.6 }}
                          >
                            {challenge}
                          </p>
                        </div>
                        <div>
                          <h2 className="mb-2 text-obscure">Nuestro Camino</h2>
                          <p
                            className="mb-0 text-obscure"
                            style={{ opacity: 0.6 }}
                          >
                            {ourWay}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ProjectsPage;
